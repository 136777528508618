.location_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #12a9cc;
  border-radius: 15px;
  border: 1px solid #fff;
  font-size: 12px;
  height: 30px;
  width: 100px;
  color: #fff;
}

.mobile-close-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  margin-inline-end: 15px;
}


.dir_btn {
  background: #12a9cc;
  border: 2.47848px solid rgba(255, 255, 255, 0.2);
  border-radius: 24.7848px;
  border: 2.48px solid #ffffff33;
  width: 200px;
  font-size: 15px;
  color: #ffffff;
}

.img_place_card {
  border-radius: 10px;
  width: 80px;
  height: 40px;
  border: none;
}
.img_place_card img {
  border-radius: 10px;
}
