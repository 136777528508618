.card_list {
  width: 355px;
  height: 65px;
  background: #ffffff;
  border-radius: 22px;
  border: none;
}

.list_card_img_container {
  margin-inline-start: 20px;
  border-radius: 20px;
}

.list_card_checkbox_container {
  position: absolute;
  width: fit-content;
}
.list_card_img_container2 {
  margin-inline-end: 20px;
  border-radius: 20px;
}

.list_card_img_container img {
  box-shadow: 0px 3px 8px 0px #00000000;
}

.list_card_desc_container {
  margin-inline-start: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #3b3b3b;
}

.list_card_desc_container p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}

.MuiPopover-paper {
  background: #f4f4f4 !important;
  filter: drop-shadow(0px -2px 40px rgba(0, 0, 0, 0.302)) !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  min-height: 450px !important;
}

.card_list_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #3b3b3b;
}

.place_card {
  border: none;
  min-height: 65px;
  background: #ffffff;
  border-radius: 22px;
}

.place_container {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #3b3b3b;
  min-height: 65px;
  display: flex;
  align-items: center;
  margin-inline-start: 20px;
  width: 200px;
  word-break: break-all;
}

.btn_place {
  position: absolute;
  width: 88px;
  height: 100%;
  top: 0px;
  background: #12a9cc;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.6);
  border: none;
}

.btn_place:hover {
  position: absolute;
  width: 88px;
  height: 100%;
  top: 0px;
  background: #12a9cc;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.6);
  border: none;
}

.list_input {
  border: none;
}

.list_input::placeholder {
  font-style: normal;
  font-size: 14px;
  color: #ababab;
}

.list_input:-ms-input-placeholder {
  font-style: normal;
  font-size: 14px;
  color: #ababab;
}

.list_input::-ms-input-placeholder {
  font-style: normal;
  font-size: 14px;
  color: #ababab;
}

.done_btn {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-decoration-line: underline;
  color: #12a9cc;
}
.MuiIconButton-colorSecondary:hover {
  background-color: transparent !important;
}

.check_item {
  cursor: pointer;
  background: rgba(226, 230, 237, 0.74);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: -9px -9px 16px 0px #ffffff99;
  box-shadow: 9px 9px 16px 0px #a3b1c699;
  width: 31px !important;
  height: 31px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.check_item div {
  font-size: 20px !important;
  color: #3b3b3b !important;
  font-weight: bold !important;
  width: auto !important;
  height: auto !important;
}

.img_cont {
  margin-inline-start: 20px;
}

.share_text {
  position: absolute;
  top: 1px;
  text-decoration: underline;
  font-size: 11px;
}
