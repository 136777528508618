
@import '~react-pro-sidebar/dist/scss/styles.scss';

//aside 
$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

.app {
  // height: 100%;
  // height: inherit;
  display: flex;
  position: relative;
  background-color: inherit;  
  
  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .block {
    display: flex;
    margin-top: 30px;
    margin-bottom: 24px;
    font-size: 14px;
    // color: #5454add_input54;
  }

 
  .pro-menu-item {
    &.active {
      background-color:#12a9cc;
    }
  }
}




.Breadcrumb {
  background: none !important;
  border: none !important;
  align-items: center;
}

.breadcrumb {
  background: none;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: none;
}

.header_page_container {
 width: 100%;
}


  .pro-sidebar{
    height: inherit !important;
    // min-height: 100% !important;
    // width: 200px !important;
    // min-width: 200px !important;
  }


  .header-fixed {
    background: #fff;
    border: none;
    // border-bottom: 1px solid #fff;
    box-shadow: 0 3px 6px 0 rgb(18 169 204 / 20%);
    padding-block: 0px;
    // margin-block: 0px;
    height: 60px;
  }

.nav-item{
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.nav-link:hover{
  color: #12a9cc;

}

.nav-link{
  color: #000 ;
}

.nav-item{
  & .active{
    color: #12a9cc;
  }
}

  .nav-item:hover{
    border-top: 1px solid #12a9cc;
    cursor: pointer;
  }


  .header-menu-mobile{
    // background-color: #12a9cc !important;
    border: none !important;
    margin: 0px;
    cursor: pointer !important;
    // color: #fff !important;
    // border-radius: 10px;
    // padding: 5px 2px;
  }

  .navbar-toggler{
    padding: 0px;
     font-size: 15px;
  }


  