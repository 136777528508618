.inital_web_view_card_search {
  position: absolute;
  z-index: 1000;
  background: rgba(244, 244, 244, 0.8);
  border: 3px solid white;
  padding: 10px 15px !important;
}

.web_view_card {
  position: absolute;
  z-index: 1000;
  background: #f4f4f4;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  overflow: auto;
  height: 100%;
  padding: 10px 15px !important;
}

.search_card_item {
  height: 50px;
  text-align: center;
  align-items: center;
  align-items: center;
  justify-content: center;
}
.search_card_item_active {
  height: 50px;
  text-align: center;
  align-items: center;
  background: #a0ddeb;
  border-radius: 15px;
  filter: drop-shadow(9px 9px 16px rgba(163, 177, 198, 0.6)),
    drop-shadow(-9px -9px 16px rgba(255, 255, 255, 0.6));
  border: 2px solid #ffffff33;
}

.search_card_div {
  height: 100%;
  /* width: 100%; */
}

.search_card_item_text {
  font-size: 11px;
  color: #12a9cc;
  font-weight: bold;
  margin-top: 5px;
}

.map_icon {
  position: absolute;
  z-index: 1000;
}

.zoom_icon {
  border-radius: 100%;
  background: #f4f4f4;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.6);
  transform: rotate(-90deg);
  box-shadow: -9px -9px 16px 0px #ffffff99;
  box-shadow: 9px 9px 16px 0px #a3b1c699;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msg_icon {
  border-radius: 100%;
  background: transparent;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.web_view_card_place {
  width: 350px;
  position: absolute;
  top: 0px;
  background: #f4f4f4;
  box-shadow: -6.62353px -18.5459px 18.5459px rgba(0, 0, 0, 0.16);
  /* transform: rotate(-180deg); */
  box-shadow: -6.623529434204102px -18.545883178710938px 18.545883178710938px 0px
    #00000029;
  height: fit-content;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  overflow-y: scroll;
  z-index: 100000;
  min-height: 400px;
}

.col_web_icon {
  bottom: 50px;
  position: absolute;
}

.search_result_container {
  overflow-y: scroll;
}

.search_result_title {
  font-weight: 600;
  font-size: 16px;
  color: #12a9cc;
}

.search_result_card_item {
  /* width: 335px; */
  min-height: 85px;
  height: 85px;;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 27.5px;
  border: none;
}

.search_result_clear_text {
  font-size: 16px;
  text-decoration-line: underline;
  color: #1ea7ca;
}

.place_name {
  font-size: 16px;
  color: #12a9cc;
}

.city_name {
  font-size: 16px;
  color: #12a9cc;
}

.user_name_text {
  font-weight: 600;
  font-size: 11px;
  color: #12a9cc;
}
.marked_location_text {
  font-size: 31.5467px;
  color: #3b3b3b;
}

.distance {
  font-size: 18px;
  color: #12a9cc;
}

.location_info_card {
  border: none;
  background: #ffffff;
  border-radius: 25.33px;
  height: 158.67px;
  justify-content: center;
}

.user_container {
  height: 100%;
}
.search_loader {
  margin-inline-end: 10px;
  color: #1ea7ca;
}

.sys_card {
  background: #ffffff;
  border-radius: 26.0761px;
  border: none;
  height: 146.97px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark_text {
  font-size: 15px;
  color: #3b3b3b;
}
.dark_text:hover {
  text-decoration: underline;
}
.follow_text {
  font-size: 15px;
  color: #3b3b3b;
  margin-inline-start: 20px;
  text-align: right;
}

.user_row {
  width: 100%;
}

.help_card {
  min-height: 300px;
  background: #ffffff;
  border-radius: 26.0761px;
  border: none;
}

.vector_container_inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
  height: 44.72px;
  background: rgba(18, 169, 204, 0.3);
  border-radius: 61.2859px;
}
.vector_container_active {
  border: 2.72px solid #ffffff33;
  background: #12a9cc;
  border: 2.72382px solid rgba(255, 255, 255, 0.2);
  box-shadow: 12.2572px 12.2572px 21.7906px rgba(163, 177, 198, 0.6),
    -12.2572px -12.2572px 21.7906px rgba(255, 255, 255, 0.6);
  box-shadow: -12.257184982299805px -12.257184982299805px 21.790550231933594px 0px
    #ffffff99;
  box-shadow: 12.257184982299805px 12.257184982299805px 21.790550231933594px 0px
    #a3b1c699;
  border-radius: 27.2382px;
  width: 95px;
  height: 44.72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more_class {
  width: 95px;
  height: 110.31px;
  background: rgba(176, 222, 232, 0.15);
  border: 2.72382px solid rgba(18, 169, 204, 0.45);
  border-radius: 27.2382px;
}

.more_text {
  font-size: 20.4286px;
  color: #89d1e1;
}

.class_type_container_inactive {
  width: 100px;
  height: 110.31px;
  background: #b0dee8;
  border: 2.72382px solid rgba(255, 255, 255, 0.2);
  border-radius: 27.2382px;
  border: 2.72px solid #ffffff33;
}

.class_type_container_active {
  width: 100px;
  height: 110.31px;
  background: #12a9cc;
  border-radius: 27.2382px;
  box-shadow: -12.257184982299805px -12.257184982299805px 21.790550231933594px 0px
    #ffffff99;
  box-shadow: 12.257184982299805px 12.257184982299805px 21.790550231933594px 0px
    #a3b1c699;
  border: 2.72px solid #ffffff33;
}

.Info_circle {
  /* margin-inline-end: 1px; */
  margin-top: 5px;
}

.time_active {
  font-size: 20px;
  color: #ffffff;
}
.time_inactive {
  font-size: 20px;
  color: #12a9cc;
}

.distance_active {
  font-size: 17px;
  color: #ffffff;
}

.distance_inactive {
  font-size: 17px;
  color: #12a9cc;
}

.arraw_card {
  height: 270.59px;
  background: #ffffff;
  border-radius: 36.0099px 36.0099px 0px 0px;
  overflow-y: scroll;
  border: none;
}

.your_location_text {
  font-size: 15.4328px;
  /* identical to box height */
  color: #3b3b3b;
}

#line_card_item {
  margin-inline: 15px;
  background-color: #a0ddeb;
}

.card_direction {
  border-top: none;
  border-inline: none;
  border-bottom-color: #a0ddeb;
  border-block-width: 0.514427px;
  border-bottom-style: solid;
  margin-inline: 4%;
}


.your_location_distance {
  font-size: 15.4328px;
  color: #1ea7ca;
}

.direction_btn {
  background: #12a9cc;
  border: 2.57508px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  width: 273px;
  height: 42px;
}

.direction_btn:focus {
  background: #12a9cc;
  border: 2.57508px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  width: 273px;
  height: 42px;
}

.direction_btn:hover {
  background: #12a9cc;
  border: 2.57508px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  width: 273px;
  height: 42px;
}
.direction_btn_text {
  font-weight: bold;
  font-size: 25px;
  color: #ffffff;
}

.search_direction_div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 2px solid #89d1e1;
  border-radius: 30px;
}

.search_dir_icon {
  margin-inline-start: 10px;
  width: 30px;
  height: 30px;
}




.search_direction_text2 {
  font-size: 13.7143px;
  color: #3b3b3b;
}

.search_direction_text3 {
  font-size: 17.1429px;
  color: #3b3b3b;
}

.place_info_card {
  height: 97.14px;
  background: #ffffff;
  border-radius: 31.4286px;
  border: none;
}

.clear_place_text {
  font-size: 12.5714px;
  text-decoration-line: underline;
  color: #12a9cc;
}

.place_btn_card {
  background: #12a9cc;
  border: 2.28571px solid rgba(255, 255, 255, 0.2);
  border-radius: 22.8571px;
}

.add_text_btn {
  font-size: 11.4286px;
  color: #ffffff;
  margin-inline-start: 4px;
}

.place_dir_text {
  font-size: 18.2857px;
  color: #12a9cc;
  text-align: center;
}

.place_dir_desc {
  font-size: 18.2857px;
  color: #12a9cc;
}
.loc_dir_distance {
  font-size: 15.4328px;
  color: #1ea7ca;
}

.category_card {
  background: #ffffff;
  border-radius: 45px;
  /* display: flex; */
  border: none;
  justify-content: space-evenly;
  height: 33px;
  min-width: 59px;
}

.img_category_container {
  border-radius: 100%;
  background: #12a9cc;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 20px;
  height: 20px;
}

.category_card_title {
  font-size: 10px;
  color: #12a9cc;
}

.slider_btn {
  position: relative;
  align-self: center;
  z-index: 100000000000000;
  box-shadow: 1px 1px 2px gray;
  width: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border: none;
}

.slider_btn:hover {
  background-color: #f4f4f4;
  border: none;
}
.slider_btn:focus {
  background-color: #f4f4f4;
  border: none;
  box-shadow: none;
}
.slider_btn_left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: none;
}

.slider_btn_right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.toggle_slider {
  transition: opacity 0.5s ease-in-out;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
  /* display: none; */
}

.search_card_col {
  /* max-width: none; */
  padding: 0px;
  margin: 0px;
}

.add_place_title {
  font-size: 20px;
  color: #3b3b3b;
}

.place_exit_container {
  background: #ffffff;
  transform: matrix(0.02, 1, -1, 0.02, 0, 0);
  box-shadow: 0px 3px 4px 0px #0000001c;
  width: 24.22px;
  height: 24.59px;
  border-radius: 100%;
  cursor: pointer;
}

.icon_label {
  margin-inline-start: 5px;
}

.action-sheet {
  background: #f4f4f4 !important;
  z-index: unset !important;
  inset: unset !important;
}
.action-sheet > div {
  background: #f4f4f4 !important;
  border-radius: 35px 35px 35px 35px !important;
}

.points_icon {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.img_container_account {
  width: 30px;
  height: 30px;
}
