.trans_card {
  background-color: #12a9cc;
  width: 280px;
  height: 180px;
  color: white;
  border: none;

  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  /* margin: auto; */
}

.map_container {
  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.download1_card {
  background-color: white;
  /* border: none; */
  /* width: 160px; */
  height: 200px;
  color: black;
  border: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.download2_card {
  background-color: #161616;
  /* border: none; */
  /* width: 160px; */
  height: 200px;
  color: white;
  border: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}

mapboxgl-ctrl-bottom-left {
  display: none;
}

#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-bottom-left {
  display: none;
}

.vl {
  border-left: 1px solid grey;
  height: 100%;
}

.search_input ::placeholder {
  color: red;
  opacity: 1; /* Firefox */
}

.search_input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

.search_input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.css-6q0nyr-Svg {
  display: none;
}

.css-yk16xz-control {
  border: none;
}

#root > div.card > div > div > div.col-lg-10 > div > div {
  border: none;
}
#root
  > div.card
  > div
  > div
  > div.col-lg-10
  > div
  > div
  > div.css-1hb7zxy-IndicatorsContainer
  > div
  > svg {
  display: none;
}

.account_card {
  /* width: 980px; */
  /* height: 200px; */
  background-color: rgb(255, 255, 255, 0.5);
  color: black;
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15); */

  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: none;
}

.card_state_two {
  max-width: 540px;
  height: 317px;
  background-color: white;

  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: none;
  margin-bottom: 10px;
}

.card_state_three {
  max-width: 380px;
  height: 326px;
  background-color: white;
  direction: rtl;

  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: none;
  margin-bottom: 10px;
}

.state4_card {
  background-color: white;
  max-width: 540px;
  /* height: 380px; */
  height: 317px;

  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
  border: none;
  border-radius: 20px;
  margin-bottom: 10px;
}

.lastcard {
  background-color: white;
  width: 300px;
  height: 90px;
  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: none;
  /* margin: 5px; */
  /* margin-bottom: 10px; */
}

.map_card {
  background-color: white;
  max-width: 380px;
  height: 380px;

  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: none;
  margin-bottom: 10px;
}
.test:after {
  content: "\2807";
  font-size: 3em;
  color: #2e2e2e;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .end_margin {
    margin-inline-end: 22px;
  }

  .end_margin_map_col {
    margin-inline-end: 22px;
  }
}

.li_list {
  list-style-image: url(../../assets/img/list_icon.png);
  list-style-position: outside;
  justify-content: space-between;
}

.ul_style {
  direction: rtl;
  list-style-position: inside;
}

.card_user_col {
  direction: rtl;
}

.trans_icon {
  /* transform: rotate(-90deg); */
}

.statistics_item {
  border: 15px solid transparent;
  border-radius: 100%;
}

.statistics_item_child {
  width: 80px;
  height: 80px;
  color: white;
  font-weight: bold;
}

.stat_content_container {
  color: #fff;
  cursor: pointer;
}

.stat_content_circle {
  width: 11.78px;
  height: 11.78px;
  border-radius: 100%;
  border: none;
  margin-inline-end: 5px;
}
#circle_1 {
  background: #00a0e9;
}

#circle_2 {
  background: #a82cd0;
}
#circle_3 {
  background: #d4767f;
}

#circle_4 {
  background: #ed9013;
}

#circle_5 {
  background: wheat;
}
.time_box {
  background-color: #fff;
  background-color: rgba(22, 22, 22, 0.9);
  width: 60px;
  height: 60px;
  border-radius: 20px;
  border: 2px solid #12a9cc;
  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
}

.time_box span {
  color: #12a9cc;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
}
.time_tile {
  font-size: 10px;
  font-style: italic;
  color: #fff;
}
