@font-face {
  font-family: 'Cairo-Bold';
  src: url('./assets/fonts/Cairo-Regular.ttf');
}

* {
  font-family: 'Cairo-Bold' !important;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: rgba(238, 240, 242, 1) !important;
}

#root {
  height: inherit;
  background-color: transparent
}
.padding0 {
  padding: 0px;
}
