.search_select_container .css-yk16xz-control {
  font-size: 16px !important;
  text-indent: 20px !important;
  border-radius: 10px !important;
  min-height: 50px !important;
  background: #f3f3f3 !important;
  border: none !important;
}
/* .css-1o4cuih-container {
  z-index: 1000;
} */
.css-1hvjw7h-menu {
  background-color: red !important;
  box-shadow: none !important;
}

.search_select_container .css-1pahdxg-control:focus {
  font-size: 16px !important;
  text-indent: 20px !important;
  border-radius: 10px !important;
  min-height: 50px !important;
  background: #f3f3f3 !important;
  border: none !important;
  box-shadow: none !important;
}
.search_select_container .css-1pahdxg-control {
  font-size: 16px !important;
  text-indent: 20px !important;
  border-radius: 10px !important;
  min-height: 50px !important;
  background: #f3f3f3 !important;
  border: none !important;
  box-shadow: none !important;
}

.search_select_container .css-1pahdxg-control:active {
  font-size: 16px !important;
  text-indent: 20px !important;
  border-radius: 10px !important;
  min-height: 50px !important;
  background: #f3f3f3 !important;
  border: none !important;
}

.form_select_container {
  width: 100%;
}

.form_select_container .css-yk16xz-control {
  height: 100% !important;
  background: #ffffff !important;
  box-shadow: none !important;
  border-radius: 22px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
}

.form_select_container .css-1pahdxg-control:focus {
  height: 100% !important;
  background: #ffffff !important;
  box-shadow: none !important;
  border-radius: 22px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
}

.form_select_container .css-1pahdxg-control {
  height: 100% !important;
  background: #ffffff !important;
  box-shadow: none !important;
  border-radius: 22px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
}

.form_select_container .css-1pahdxg-control:active {
  height: 100% !important;
  background: #ffffff !important;
  box-shadow: none !important;
  border-radius: 22px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
}

.search_phone_container,
.search_date_container {
  color: black !important;
  font-size: 16px !important;
  text-indent: 20px !important;
  border-radius: 10px !important;
  height: 50px !important;
  background: #f3f3f3 !important;
  border: none !important;
  color: black !important;
}

.search_phone_container .react-tel-input {
  width: 100% !important;
  position: unset !important;
  background: #f3f3f3 !important;
  text-indent: 20px !important;
  border-radius: 10px !important;
  color: black !important;
}

.search_phone_container .react-tel-input .form-control {
  width: 100% !important;
  height: 50px !important;
  border: none !important;
  background: #f3f3f3 !important;
  text-indent: 20px !important;
  border-radius: 10px !important;
  color: black !important;
  font-weight: unset !important;
}

.search_phone_container .react-tel-input .selected-flag {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: unset !important;
}
.search_phone_container .react-tel-input .selected-flag .flag {
  position: unset !important;
  top: unset !important;
  margin: unset !important;
}
