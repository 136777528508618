.sign_card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  border: none;
}

.login_switch {
  display: inline !important;
  height: 50px;
  width: 50%;
  cursor: pointer;
  font-size: 16px;
  border-radius: none;
}

.login_switch_active {
  color: #fff;
  border: 1px solid #12a9cc;
  background-color: #12a9cc;
}

.login_switch_inactive {
  color: #12a9cc;
  border: 1px solid #12a9cc;
  background-color: #fff;
}

.login_switch_active:hover {
  color: #12a9cc;
  border: 1px solid #12a9cc;
  background-color: rgba(18, 169, 204, 0.5);
}

.login_switch_active:focus {
  color: #12a9cc;
  border: 1px solid #12a9cc;
  background-color: rgba(18, 169, 204, 0.5);
}

.login_switch_active:active {
  color: #12a9cc;
  border: 1px solid #12a9cc;
  background-color: rgba(18, 169, 204, 0.5);
}

.login_switch_inactive:hover {
  color: #fff;
  border: 1px solid #12a9cc;
  background-color: rgba(18, 169, 204, 0.5);
}

.login_switch_inactive :focus {
  color: #fff;
  border: 1px solid #12a9cc;
  background-color: rgba(18, 169, 204, 0.5);
}

.login_switch_inactive :active {
  color: #fff;
  border: 1px solid #12a9cc;
  background-color: rgba(18, 169, 204, 0.5);
}

.container_add_input {
  /* width: 312px; */
  min-height: 44px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px 0px #1b1b1b17;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #12a9cc;
}

.container_add_input .react-tel-input .form-control,
.add_input:focus {
  height: 100%;
  border: none !important;
  border-radius: 10px;
  box-shadow: none !important;
  outline: none !important;
}

.container_add_input .react-tel-input .form-control,
.add_input:active {
  height: 100%;
  border: none !important;
  border-radius: 10px;
  box-shadow: none !important;
  outline: none !important;
}
.container_add_input .react-tel-input .form-control,
.add_input:focus {
  height: 100%;
  border: none !important;
  border-radius: 10px;
  box-shadow: none !important;
  outline: none !important;
}

.container_add_input .react-tel-input .form-control {
  border: none !important;
  height: 100%;
  width: 100%;
  border-radius: 22px;
}

.container_add_input .react-tel-input .flag-dropdown {
  border: none;
  background-color: transparent;
}

.strik_icon {
  margin-inline-start: 15px;
}
.strik_icon2 {
  margin-inline-start: 15px;
}
.strik_icon2 :hover {
  color: #12a9cc;
}

.login_wrapper {
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/img/bg.svg');
  /* background-repeat: no-repeat; */
  /* background-position: center center; */
  /* background-size: cover; */
  /* background-color: red; */
  background-clip: border-box;
}
/* .form-control {
    height: 50px !important;
    border: none !important;
    background: #ffffff !important;
    border-radius: 22px !important;
    font-family: Titillium Web !important;
    font-size: 15px !important;
    color: #12a9cc !important;
    font-weight: bold !important;
  } */
.mapy_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook_btn {
  background: #3552e8;
  border-radius: 33.3911px;
  padding-inline: 10%;
  height: 55.36px;
  animation-duration: 0ms;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: 12.302px;
  line-height: 108.1%;
  letter-spacing: 0.05em;
  color: #f6f1fb;
  display: flex;
  align-items: center;
  width: 250px;
}

.google_btn {
  border: 2px solid #3f414e;
  box-sizing: border-box;
  border-radius: 33.3911px;
  padding-inline: 10%;
  width: 250px;
  height: 55.36px;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: 12.302px;
  line-height: 108.1%;
  letter-spacing: 0.05em;
  color: #3f414e;
  border: 2px solid #3f414e;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.mobile_signup_text {
  font-size: 20px;
}

.mobile_signin_text {
  font-size: 20px;
  line-height: 15px;
}

.sign_input_style,
.sign_password_field,
.date_container input {
  height: 44px;
  background: #ffffff;
  border-radius: 22px;
  font-size: 14px;
  color: #3b3b3b !important;
  font-weight: bold;
  border: none;
  width: 100%;
  background-color: transparent !important;
}
/* .form-control {
    color: #12a9cc;
  }
  .form-control:focus {
    color: #12a9cc;
  } */

.phone_input_container {
  background: #ffffff;
  border-radius: 22px !important;
  border: none !important;
  height: 50px !important;
}

.phone_input {
  background-color: transparent !important;
  border: none !important;
  height: 100% !important;
  width: 100% !important;
  font-size: 15px !important;
  color: #12a9cc !important;
}
.phone_dropdown {
  border-radius: 22px !important;
  text-align: center !important;
  justify-content: center !important;
}
.phone_btn {
  border: none !important;
  background-color: transparent !important;
  height: 100% !important;
  border-radius: none !important;
}
.phone_btn:focus {
  background-color: transparent !important;
}

.react-tel-input .selected-flag {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.react-tel-input {
  direction: ltr !important;
}
.react-tel-input .selected-flag .arrow.up {
  display: none !important;
}

.react-tel-input .selected-flag .arrow {
  display: none !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.sign_password_field {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 314px;
}

.remove_focuesd_input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.phone_input {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.pass_input {
  font-size: 15px;
  color: #12a9cc;
}

.sign_btn {
  background: #12a9cc;
  border-radius: 20px;
  border: 2px solid #ffffff33;
  width: 189.29px;
}

.sign_btn:hover {
  border-radius: 20px;
  border: 2px solid #ffffff33;
  background-color: white;
  width: 189.29px;
}
.sign_text {
  font-size: 15px;
  color: #ffffff;
}
.sign_btn:hover .sign_text {
  color: #12a9cc;
}

.spinner_color {
  color: #ffffff;
}
.sign_btn:hover .spinner_color {
  color: #12a9cc;
}
.eye_pass {
  filter: drop-shadow(9px 9px 16px rgba(163, 177, 198, 0.6)),
    drop-shadow(-9px -9px 16px rgba(255, 255, 255, 0.6));
}

.react-datepicker {
  border: none !important;
  font-size: 1rem !important;
  background-color: white !important;
}
.react-datepicker-wrapper {
  width: fit-content !important;
}

.date_col input {
  max-width: 314px;
}

.input_container {
  width: 100%;
  height: 100%;
}
/* .form-control:focus {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
  } */
.layer,
._loading_overlay_wrapper {
  position: absolute;
  background: rgb(114, 111, 111, 0.3);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
._loading_overlay_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone_card {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  border: none;
  color: #12a9cc;
  background-color: #eef0f2;
  border: 1px solid #fff;
}
.dropzone_card p {
  font-size: 12px;
}
.dropzone_logo {
  font-size: 50px;
}
.dropzone_card:hover {
  color: #12aacccc;
}

.img_remove_icon {
  position: absolute;
  font-size: 15px;
  z-index: 1000;
}

.react-tel-input .form-control {
  font-weight: bold;
}

.lang_btn {
  background: #12a9cc !important;
  border-radius: 10px;
  border: 2px solid #ffffff33;
  width: 100px;
  height: 40px;
  color: #fff;
  border: none;
  font-weight: bold;
  box-shadow: none !important;
}
.lang_btn:hover {
  background: #12a9cc !important;
  border-radius: 10px;
  border: 2px solid #ffffff33;
  width: 100px;
  height: 40px;
  color: #fff;
  border: none;
  font-weight: bold;
  box-shadow: none !important;
}
.lang_btn:focus {
  background: #12a9cc !important;
  border-radius: 10px;
  border: 2px solid #ffffff33;
  width: 100px;
  height: 40px;
  color: #fff;
  border: none;
  font-weight: bold;
  box-shadow: none !important;
}
.lang_btn:active {
  background: #12a9cc !important;
  border-radius: 10px;
  border: 2px solid #ffffff33;
  width: 100px;
  height: 40px;
  color: #fff;
  border: none;
  font-weight: bold;
  box-shadow: none !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background: #12aacccc !important;
  border-radius: 10px !important;
}

.resend_btn {
  cursor: pointer;
  background: transparent;
  border: none;
}

.resend_btn:focus {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

.resend_btn:active {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}
.resend_btn:hover {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}
.resend_btn:disabled {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}
.resend_container {
  background: #12a9cc;
  padding-inline: 10px;
  border-radius: 5px;
  padding-block: 5px;
  color: white !important;
}

.vertical_line {
  border-left: 1px solid gray;
}

.add_input:disabled {
  background: transparent;
  text-align: center;
}

.add_input {
  height: 100%;
  border: none;
  border-radius: 22px;
}

.removed_input_style {
  border: none;
  background-color: transparent;
}

.removed_input_style:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.removed_input_style:disabled {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

.input_style:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #12a9cc;
}
.input_style:active {
  outline: none;
  box-shadow: none;
  border: 1px solid #12a9cc;
}
.input_style:hover {
  outline: none;
  box-shadow: none;
  border: 1px solid #12a9cc;
}

