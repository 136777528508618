.card_branch_text {
  font-size: 12px;
  color: #002345;
}

.link {
  font-weight: bold;
  font-size: 10px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #002345;
}

.categroy_card_details {
  background: #f5f5f5;
  border-radius: 17px;
  border: none;
  overflow: auto;
}

.categroy_card_details2 {
  background: #f5f5f5;
  border-radius: 17px;
  border: none;
  width: 400px;
  height: 80px;
  overflow: auto;
}

.categroy_name {
  font-size: 16px;
  color: #12a9cc;
}
.categroy_city {
  font-size: 16px;
  line-height: 27px;
  color: #12a9cc;
}

.category_div {
  width: 100%;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  box-shadow: -9px -9px 16px 0px #ffffff99;
  box-shadow: 9px 9px 16px 0px #a3b1c699;
  border: none;
  font-size: 10px;
  color: #ffffff;
  /* width: 50px; */
  height: 32.84px;
  align-items: center;
  display: flex;
}
.category_div_text {
  font-size: 10px;
}

.img_div_cat {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  align-items: center;
}

.card_customer_text {
  font-size: 12px;
  color: #002345;
}
.card_customer_desc {
  font-size: 9px;
  color: #002345;
}

.place_review_card2 {
  height: 66px;
  border-radius: 8.35232px;
  border: none;
  background: white !important;
  margin-block: 5px;
  color: white;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(-5px -5px 10px #fafbff), drop-shadow(5px 5px 10px #a6abbd);
  box-shadow: 5px 5px 10px 0px #a6abbd;
  overflow: auto;

  /* box-shadow: -5px -5px 10px 0px #fafbff; */
}

.div_location .react-tel-input .form-control {
  border: none !important;
  width: inherit;
  outline: none !important;
  border-radius: 10px !important;
}

.div_location .react-tel-input .flag-dropdown {
  /* border: none !important; */
  background-color: white !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-left: none;
}

.div_location .css-yk16xz-control {
  border-radius: 10px !important;
  border: none !important;
}

.branch_card {
  width: 40px;
  height: 35px;
  margin-inline-start: 5px;
}

.branch_card img {
  border-radius: 10px;
}

.review_user_container {
  width: 40px;
  height: 40px;
}
.review_user_container img {
  border-radius: 100%;
}
