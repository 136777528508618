.violation_card {
  background: #ffffff;
  border-radius: 10px;
  border: none;
}

.violation_card_header {
  background-color: transparent;
  border-radius: 10px;
  border-bottom: 2px solid rgba(18, 169, 204, 0.2);
  padding: 0px !important;
}
.violation_card_body {
  padding: 0px;
  margin-top: 5%;
}

.card_violation_item {
  min-width: 81px;
  min-height: 81px;
  background: #12a9cc;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: none;
  margin-inline: 20px;
}

.card_violation_item:hover {
  background: #eef0f2;
  border: 2px solid rgba(18, 169, 204, 0.52);
}
.icon_violation_card {
  color: #fff;
  font-size: 25px;
}
.card_violation_item:hover .icon_violation_card {
  color: #12a9cc;
}

.card_violation_item:hover .card_violation_item_p {
  font-weight: 600;
  font-size: 15px;
  color: #12a9cc;
}
.card_violation_item_p {
  color: #3b3b3b;
}
.card_violation_item_p_active {
  font-weight: bold;
  font-size: 15px;
  color: #12a9cc;
}
.card_violation_item_active {
  background: #eef0f2;
  border: 2px solid rgba(18, 169, 204, 0.52);
  min-width: 81px;
  min-height: 81px;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  margin-inline: 20px;
}

.violation_table_head_tr {
  text-align: center;
}

.icon_sort {
  display: block;
  color: #12a9cc;
  /* font-size: 14px; */
  cursor: pointer;
}
.icon_sort:hover {
  display: block;
  color: #90cddb;
  /* font-size: 14px; */
  cursor: pointer;
}
.icon_sort_active {
  display: block;
  color: #90cddb;
  /* font-size: 14px; */
  cursor: pointer;
}

.head_title {
  font-weight: 600;
  /* font-size: 18px; */
  color: #0d1c2e;
}

.tbody_container {
  width: 100px;
  height: 80px;
}
.tbody_container2 {
  width: 100%;
  height: 80px;
}
.tbody_container img {
  filter: drop-shadow(-2.5px -2.5px 5px #fafbff)
    drop-shadow(2.5px 2.5px 5px #a6abbd);
  border-radius: 7.04023px;
}

.td_div {
  justify-content: space-evenly;
}

.td_table_text {
  font-size: 15px;
  color: #0d1c2e;
}
.table thead th {
  border: none !important;
  border-top: 2px solid rgba(18, 169, 204, 0.1) !important;
  border-bottom: 2px solid rgba(18, 169, 204, 0.1) !important;
}
.table-bordered td,
.table-bordered th {
  border: none !important;
  border-left: 2px solid rgba(18, 169, 204, 0.1) !important;
}

.remove_comp_icon {
  position: absolute;
  top: 2px;
  color: red;
  cursor: pointer;
  font-size: 15px;
}
