.sup_card {
  width: 311px;
  height: 311px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  border: none;
  text-align: center;
}

.sta_support_card {
  width: 210px;
  height: 185px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border: none;
  padding: 1%;
  text-align: center;
}

.support_card {
  width: 330px;
  height: 350px;
  border-radius: 12px;
  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
  border: none;
  overflow: auto;
  overflow-x: hidden;
  padding-block: 10px;
}
.support_card::-webkit-scrollbar {
  width: 0px;
}

.support_place_card {
  /* width: 290px; */
  height: 66px;
  border-radius: 8.35232px;
  border: none !important;
  background: #002345;
  margin-block: 5px;
  color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.place_review_card {
  height: 66px;
  border-radius: 8.35232px;
  border: none !important;
  background: white;
  margin-block: 5px;
  color: white;
  overflow: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.slick-arrow {
  display: none !important;
}

.place_card_div {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
}

.img_post {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
}

.comp_account_img {
  /* opacity: 0.8; */
  width: 42.14px;
  height: 42.14px;
  border-radius: 10px;
}

.img_review_container {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
