.review_card {
  padding: 10px;
  height: 150px;
  background: #f6f6f6;
  border: none;
  box-shadow: 0px 3px 6px 0px #00000040;
  overflow: auto;
  width: 400px;
}

.review_card .title {
  font-size: 15px;
  color: #242424;
}

.review_card .desc {
  font-size: 15px;
  color: #242424;
}

.review_card .img_container {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}
.review_card .img_container img {
  border-radius: 100%;
}

.review_text {
  margin-inline-start: 10px;
  width: calc(100%-90px);
  height: calc(100%-90px);
}

.review_card_name {
  position: relative;
  /* position: absolute; */
  top: 0px;
}
