
.column_option {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}


.map_icon {
  bottom: 30px;
  left: 10px;
}

.primary_color {
  color: #12a9cc;
}

.dark_color {
  color: #3b3b3b;
}

.no_connection_title {
  font-size: 38px;
  line-height: 43px;
  color: #000000;
}
.no_connection_desc {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  opacity: 0.57;
}

.no_connection_btn {
  background: #58c0ea;
  border-radius: 10px;
  box-shadow: -9px -9px 16px 0px #ffffff99;
  box-shadow: 9px 9px 16px 0px #a3b1c699;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  border: none;
  width: 224px;
  height: 50px;
}
.lang_text {
  text-decoration: underline;
  cursor: pointer;
  color: #000000;
  font-weight: bold;
}

.lang_text:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #12a9cc;
}

.share_icon {
  margin-inline: 10px;
}

.share_text {
  text-decoration: underline;
  font-weight: bold;
  color: #12a9cc;
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: #12a9cc;
  border-color: #12a9cc;
}

.page-link {
  color: #12a9cc;
}



.loc_link {
  cursor: pointer;
  color: #12a9cc;
  margin-inline: 5px;
}

.lighBlueColor {
  color: #12a9cc;
}
.leaflet-div-icon {
  background: none !important;
  border: none !important;
  /* background-image: url(../assets/img/point2.svg) !important; */
  background: #fff !important;
  border-radius: 100% !important;
  border: 2px solid #12a9cc !important;

  padding: 0% !important;
}
