.photo_card {
  min-width: 314px;
  min-height: 85px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  justify-content: center;
}

.dropzone_card_images {
  width: 45px;
  height: 45px;
  background: rgba(18, 169, 204, 0.4);
  border: none;
  border-radius: 15px;
}

.date_column .form-control {
  border: none;
}

.cont_card {
  border: none;
  background: #ffffff;
  height: 120px;
  box-shadow: 0px -1px 20px 0px #00000000;
  border-radius: 15px;
  overflow: auto;
}
.desc_cont {
  margin-inline-start: 5px;
}
.desc_cont_overview1 {
  font-size: 15px;
  color: #3b3b3b;
  font-weight: bold;
}
.desc_cont_overview2 {
  font-size: 15px;
  font-weight: normal;
}

.img_cont {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
