

.page-container{
  width: 100%;
}
.alignItem {
  align-items: center;
}


.breakWord{
  word-break: break-word;
}

.maxWidth {
  max-width: none;
}
.maxHeight {
  max-height: none;
}

.fitContent {
  height: fit-content;
}
.weightFont {
  font-weight: bold;
}

.leftFloat {
  float: left;
}
.rightFloat {
  float: right;
}

.blue_color {
  color: #002345;
}

.light_color {
  color: #12a9cc;
}

.white_color {
  color: white;
}
.inline_tag {
  display: inline;
}
.commentText {
  /* color: #67748  e; */
  color: #5f6368;
}
.padding0_margin0 {
  padding: 0px;
  margin: 0%;
}

.padding0 {
  padding: 0px;
}

.noActiveColor {
  color: #252f40;
}

.cursor {
  cursor: pointer;
}

.img_head {
  width: 45px;
  height: 45px;
  border-radius: 16.875px;
  border: 1.40625px solid #007aff;
}
.css-sfz5h4 a {
  display: none !important;
}

.input_search {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: #ffffff;
  border: 1px solid #002345;
  border-radius: 8px;
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .stat_home_col {
    margin-top: 6px;
  }
  .st_card_count {
    margin-inline-start: 10px;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .st_card_count {
    margin-inline-start: 10px;
  }
  .stat_home_col {
    margin-top: 6px;
  }
}

.create_btn {
  width: 240px;
  height: 50px;
  background: linear-gradient(92.68deg, #12a9cc 0%, #00a0e9 100%);
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px #a6abbd;
  border-radius: 90px;
  border: none;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  align-items: center;
  text-align: center;
  align-items: center;
}

.bold {
  font-weight: bold;
}
.fullWidthHeight {
  width: 100%;
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}
.makeStyles-inputRoot-12 {
  height: 100% !important;
}
.MuiBox-root-27 {
  padding: 0px !important;
}

.imgFull {
  width: 100%;
  height: 100%;
  position: relative;
}
.page_title_item {
  font-weight: bold;
  font-size: 28px;
  color: #272d3b;
}
.add_text {
  font-size: 15px;
  color: #f5fbff;
}
.add_btn {
  height: 44px;
  /* width: 100%; */
  padding: 8px;
  margin: 0px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 1px;
  border: 1px solid #12a9cc;
  background-color: #12a9cc;
}

.add_btn:hover {
  height: 44px;
  /* width: 100%; */
  padding: 8px;
  margin: 0px;
  padding: 8px;
  cursor: pointer;
  background: none;
  color: #12a9cc;
  font-size: 14px;
  border: 1px solid #12a9cc;
}

.add_btn:hover .add_text {
  color: #12a9cc;
}
.search_card {
  border: none;
  background: #ffffff;
  border-radius: 5px;
  /* overflow: auto; */
  /* z-index: auto; */
}

.table_card {
  border: none;
  background: #ffffff;
  border-radius: 5px;
  height: fit-content;
  overflow-y: scroll;
}

/* .table_card::-webkit-scrollbar {
  width: 0px;
} */

/* .table_card > table {
  overflow: scroll;
} */

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #272d3b;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #12a9cc;

  border-color: #12a9cc;
  box-shadow: 2.5px 2.5px 5px 0px #a6abbd;

  box-shadow: -2.5px -2.5px 5px 0px #fafbff;
  filter: drop-shadow(-2.5px -2.5px 5px #fafbff),
    drop-shadow(2.5px 2.5px 5px #a6abbd);
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.is_active {
  background: #12a9cc;
  border-radius: 25px;
}

.active_text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #f5fbff;
  width: 72.79px;
}

.not_active {
  filter: drop-shadow(0px 3px 6px rgba(39, 45, 59, 0.2));
  background: #0d0d0d;
  border-radius: 25px;
}

.inActive_text {
  font-family: STC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 72.79px;

  color: #f5fbff;
}

/* .dropdown-toggle::after {
  display: none !important;
} */

.inlineBlock {
  display: inline-block;
}

.input_style,
.div_location {
  background: #ffffff;
  border: 1px solid #12a9cc;
  box-sizing: border-box;
  border-radius: 10px;
  mix-blend-mode: normal;
}

.div_search {
  background: #ffffff;
  border-radius: 8px;
  border: none;
  filter: drop-shadow(9px 9px 16px rgba(163, 177, 198, 0.6)),
    drop-shadow(-9px -9px 16px rgba(255, 255, 255, 0.6));
  box-shadow: -9px -9px 16px 0px #ffffff99;
  box-shadow: 9px 9px 16px 0px #a3b1c699;
  height: 40px;
}
.center {
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}


.btn_save {
  background: #12a9cc;
  box-shadow: 7.7161545753479px 7.7161545753479px 15.4323091506958px 0px #a6abbd;
  box-shadow: -7.7161545753479px -7.7161545753479px 15.4323091506958px 0px #fafbff;
  filter: drop-shadow(-7.71615px -7.71615px 15.4323px #fafbff),
    drop-shadow(7.71615px 7.71615px 15.4323px #a6abbd);
  border: 0.77px solid;
  border-image-source: linear-gradient(0deg, #e7ebf0, #e7ebf0),
    linear-gradient(
      317.7deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 105.18%
    );
  border-radius: 15px;
  width: 120.63px;
  height: 34.47px;
  font-size: 14px;
  color: #f5fbff;
}

.card_add_text {
  font-size: 12px;
  color: #fff;
}
.card_add_btn {
  background: linear-gradient(92.68deg, #12a9cc 0%, #00a0e9 100%);
  box-shadow: -3px -3px 6px rgba(250, 251, 255, 0.4),
    3px 3px 6px rgba(53, 55, 62, 0.61);
  border-radius: 10px;
  box-shadow: 3px 3px 6px 0px #35373e9c;

  border: none;
}

.makeStyles-list-1 {
  width: fit-content !important;
}

.menu_tab {
  color: #12a9cc;
  border: 1px solid white;
  margin-top: 10px;
  cursor: pointer;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .menu_options {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .menu_options {
    display: none;
  }
}

.menu_head {
  padding: 0px;
}

.overFlowY {
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  /* width: 0.5px !important; */
  /* width: 1px !important; */
  width: 0px !important;
}

/* Track */
::-webkit-scrollbar-track {
  /* background-color: rgba(59, 182, 209, 0.7) !important; */
  background-color: transparent;
  width: 0.2 !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
  border-radius: 10px !important;
}

.option_div {
  margin-inline: 20px;
}
.option {
  display: flex;
}

@media (min-width: 150px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .option {
    display: none;
  }

  .option_menu {
    display: flex;
  }
}
@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  .option_menu {
    display: flex;
  }
}
@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .option_menu {
    display: flex;
  }
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .option_menu {
    display: flex;
  }
}
@media (min-width: 1232px) {
  /* big landscape tablets, laptops, and desktops */
  .option_menu {
    display: flex;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .option {
    display: flex;
  }
  .option_menu {
    display: none;
  }
}

.flex {
  display: flex;
}

.block {
  display: block;
}
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.img_table {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.create_card {
  background: #fff;

  /* min-height: 500px; */
  overflow: none;
  border: none;
  padding: 50px 50px;
}

.page_create_title {
  font-weight: 300;
  font-size: 26.8043px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #0d1c2e;
}

.add_img {
  position: absolute;
  top: 10px;
  margin-inline-start: 11px;
}

.img_container {
  width: 150px;
  height: 150px;
}
.img_container img {
  border-radius: 40px;
}

.save_btn {
  height: 44px;
  width: 100%;
  padding: 8px;
  margin: 0px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
  border: 1px solid #12a9cc;
  background-color: #12a9cc;
}

.save_btn:hover {
  height: 44px;
  width: 100%;
  padding: 8px;
  margin: 0px;
  padding: 8px;
  cursor: pointer;
  background: none;
  color: #12a9cc;
  font-size: 14px;
  border: 1px solid #12a9cc;
}

.close_btn:hover {
  height: 44px;
  width: 100%;
  padding: 8px;
  margin: 0px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
  border: 1px solid #12a9cc;
  background-color: #12a9cc;
}

.close_btn {
  height: 44px;
  width: 100%;
  padding: 8px;
  margin: 0px;
  padding: 8px;
  cursor: pointer;
  background: none;
  color: #12a9cc;
  font-size: 14px;
  border: 1px solid #12a9cc;
}

.container_add_input2 {
  height: 44px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 16px 0px #1b1b1b17;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_card_container {
  border: none;
  height: 85px;
  background: #ffffff;
  border-radius: 10px;
  width: 314px;
  overflow-y: auto;
  box-shadow: 0px 4px 16px 0px #1b1b1b17;
  border-radius: 22px;
}
.img_card_container::-webkit-scrollbar {
  width: 0px !important;
}

.height100 {
  height: 100%;
}

.mapCard {
  border: 2px solid white;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.edit_icon_type {
  margin-inline-start: 10px;
  cursor: pointer;
}

.badge_item {
  padding-block: 10px;
  padding-inline: 15px;
}

.pending {
  background: #0d0d0d;
}

.approved {
  background: #12a9cc;
}

.rejected {
  background: #0d1c2e;
}

.view_text {
  text-decoration: underline;
  color: #12a9cc;
  cursor: pointer;
}

.opening_hours_card {
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
  border: 1px solid #12a9cc;
  /* overflow: auto; */
}

.search_input {
  font-size: 16px;
  text-indent: 20px;
  /* width: 100%; */
  border-radius: 10px;
  height: 50px;
  background: #f3f3f3 !important;
  border: none !important;
}
.search_btn {
  background-color: #12a9cc;
  color: white;
  font-size: 16px;
  border: none;
  padding-inline: 40px;
  border-radius: 5px;
  height: 40px;
}

.modal_btn {
  background-color: white;
  color: #12a9cc;
  border: 1px solid #12a9cc;
  outline: none;
  box-shadow: none;
}
.modal_btn:hover {
  background-color: white;
  color: #12a9cc;
  border: 1px solid #12a9cc;
  outline: none;
  box-shadow: none;
}

.modal_btn:active {
  background-color: white;
  color: #12a9cc;
  border: 1px solid #12a9cc;
  outline: none;
  box-shadow: none;
}

.modal_btn:focus {
  background-color: white;
  color: #12a9cc;
  border: 1px solid #12a9cc;
  outline: none;
  box-shadow: none;
}

.search_btn:hover {
  background-color: white;
  color: #12a9cc;
  border: 1px solid #12a9cc;
  outline: none;
  box-shadow: none;
}
.search_btn:active {
  background-color: white;
  color: #12a9cc;
  border: 1px solid #12a9cc;
  outline: none;
  box-shadow: none;
}
.search_btn:focus {
  background-color: white;
  color: #12a9cc;
  border: 1px solid #12a9cc;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.form-control :active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-control :hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.remove_btn {
  height: 44px;
  background: #d10000;
  border: none;
  width: 80px;
  border-radius: 5px;
  border: none;
  border-radius: 11.8658px;
  margin-inline-start: 10px;
}

.remove_btn:hover {
  width: 80px;
  height: 44px;
  background: #fff;
  border-radius: 5px;
  color: #d10000;
  border: 1px solid #d10000;
  border-radius: 11.8658px;
  margin-inline-start: 10px;
}

.remove_btn:hover .remove_text {
  color: #d10000;
}

.flatpickr-input input {
  background-color: transparent;
}

.flatpickr-input .form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
}

.table_dropdown_menu {
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.borderR10 {
  border-radius: 10px;
}
.borderR100 {
  border-radius: 100%;
}

.width100 {
  width: 100%;
}

.weight {
  font-weight: bold;
}
*,
:after,
:before {
  box-sizing: border-box !important;
}

.row_page {
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgb(0 0 0 / 23%);
  color: #fff;
}
.custom-ui > h1 {
  margin-top: 0;
  color: #fff;
}
.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.container_status {
  width: fit-content;
  height: fit-content;
  border-radius: 25px;
  
}

.inSelect_btn_status {
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  width: fit-content;
  height: fit-content;
  transition: all 0.5s ease 0s;
  border: none;

  margin-inline: 2px;
  color: black;
  background-color: inherit;
  /* background-color: transparent; */
}

.inSelect_btn_status img {
  box-shadow: 1px 3px 7px rgba(59, 59, 59, 0.5);
  border-radius: inherit;
  transition: all 0.5s ease 0s;
}
.inSelect_btn_status:disabled {
  background-color: inherit;
}
.selected_btn_status:disabled {
  background-color: inherit;
  transition: all 0.5s ease 0s;
}

.selected_btn_status {
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  width: fit-content;
  height: fit-content;
  transition: all 0.5s ease 0s;
  border: none;
  box-shadow: none;
  margin-inline: 2px;
  color: #fff;
  background-color: inherit;

  /* background-color: #28bae6; */
}

.container_status span {
  /* font-weight: bold; */
  display: block;
}

.error_alert .swal-overlay--show-modal .swal-modal {
  background-color: 'red !important';
}

.swal-modal {
  background-color: #f27474 !important;
}

.swal-icon {
  border: none !important;
  border-radius: none !important;
  background-image: url(../img/error_icon.svg);
}
.swal-icon--error__line {
  display: none !important;
}

.swal-title {
  color: #fff !important;
  font-weight: none !important;
}
.swal-button-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
.img_conteiner_palce {
  width: 50px;
  height: 50px;
}
.swal-button {
  background-color: #fff !important;
  width: 100% !important;
  color: #f27474 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.swal-button:hover {
  color: #fff !important;
  width: 100% !important;
  background-color: #f27474 !important;
  border: 1px solid #fff !important;
}

.dropdown-menu .show {
  transform: none !important;
  left: none !important;
  direction: rtl !important;
}

.image_profile_container {
  background: #fff;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 2px dotted #28bae6;
}

.image_profile_container img {
  border-radius: 100%;
}

.blueLight {
  color: #28bae6;
}
.redColor {
  color: red;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  border-radius: 0px !important;
}

.profile_card {
  overflow: auto;
  width: 100%;
  /* border-radius: 30px; */
  background: #eef0f2;
  /* border: 1px solid #fff; */
  background: none;
  /* background-image: url(../assets/img/bg.svg);
  background-position: center center;
  background-origin: border-box;
  background-size: cover; */
  /* border: 2px solid #28bae6; */
  /* padding: 10px; */
  box-shadow: 10.0456px 10.0456px 17.8588px rgba(163, 177, 198, 0.6),
    -10.0456px -10.0456px 17.8588px rgba(255, 255, 255, 0.6);
}

.img_profile_container {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 1px solid #28bae6;
  cursor: pointer;
}

.normal {
  font-weight: normal;
}

.hours_profile_btn {
  background: #28bae6;
  border-radius: 10px;
  color: white;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  /* transition: all 0.5s ease 0s; */
}

.hours_profile_btn:hover {
  background: transparent;
  border-radius: 10px;
  color: #28bae6;
  width: 100%;
  height: 100%;
  outline: none;
}
.hours_profile_btn:active {
  background: transparent;
  border-radius: 10px;
  color: #28bae6;
  width: 100%;
  height: 100%;
  outline: none;
}
.hours_profile_btn:focus {
  background: transparent;
  border-radius: 10px;
  color: #28bae6;
  width: 100%;
  height: 100%;
  outline: none;
}

.header_card {
  border: none;
  background: #ffffff;
  border-radius: 5px;
  min-height: 80px;
  transition: all 0.5s ease 0s;
  padding-inline: 20px;
  position: relative;
}

.border_list_hr {
  margin: 0px;
  padding: 0px;
  background-color: #28bae6;
  opacity: 0.2;
}

.parent-container {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}
.child-item {
  margin: 5px;
  text-align: center;
  padding: 10px;
  width: 9%;
}

.child-item_place {
  margin: 5px;
  text-align: center;
  padding: 10px;
  width: 10%;
}

.unset_overflow {
  overflow: unset;
}

.table_container {
  position: absolute;
  /* z-index: 1000; */
  height: fit-content;
  max-height: 100vh;
  overflow: scroll;
}

.gm-style .gm-style-iw-c {
  /* padding-inline: 150px; */
  /* padding-block: 30px; */
  padding: 20px !important;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  /* position: relative; */
  padding: 0px;
  /* min-width: 300px; */
  height: 60px;
}

.gm-style .gm-style-iw-c div {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0px;
}

.gm-style-iw-d {
  max-height: unset !important;
  overflow: unset !important;
  font-weight: bold;
}

.arraw_card_container {
  position: absolute;
  top: 0%;
  height: 100%;
  color: white;
  background-color: #28bae6;
  margin-block: 2px;
  border-start-end-radius: 20px;
  border-end-end-radius: 20px;
}

.arraw_card_container:hover {
  background-color: rgba(40, 186, 230, 0.5);
}

.remove_icon_search {
  margin-inline-end: 10px;
  font-size: 18px;
  cursor: pointer;
  color: gray;
}
.remove_icon_search:hover {
  color: gray;
}

.react-confirm-alert-overlay {
  z-index: 1000000000000 !important;
}

.row_status_container {
  padding-inline: 8px;
}

.round2 {
  position: relative;
}

.round2 label {
  background-color: #fff;
  border: 1px solid #272d3b;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  /* left: 0; */
  position: absolute;
  top: 0;
  width: 28px;
}

.round2 input[type='checkbox'] {
  visibility: hidden;
}

.round2 input[type='checkbox']:checked + label {
  background-color: #12a9cc;

  border-color: #12a9cc;
  box-shadow: 2.5px 2.5px 5px 0px #a6abbd;

  box-shadow: -2.5px -2.5px 5px 0px #fafbff;
  filter: drop-shadow(-2.5px -2.5px 5px #fafbff),
    drop-shadow(2.5px 2.5px 5px #a6abbd);
}

.round2 input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.preview_card_message {
  border: 1px solid #12a9cc;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  margin-inline: 10px;
}
.remove_icon {
  visibility: hidden;
}
.preview_card_message:hover .remove_icon {
  visibility: visible;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 169, 204, 0.5);
  z-index: 1000;
  border-radius: 10px;
  top: 0px;
}
.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #12a9cc !important;
}

.alice-carousel__dots-item:not(.__custom) {
  width: 12px !important;
  height: 12px !important;
}

.fontSize10 {
  font-size: 10px;
}

.backgroundColor {
  background-color: #eef0f2;
}

.app_container {
  width: 100% !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.header-fixed .app-header {
  position: fixed;
  z-index: 1020;
  width: 100%;
}
.comp_paginate_item {
  background-color: white;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  transition: all 0.5s ease 0s;
  border: 1px solid rgba(18, 169, 204, 0.5);
  margin-inline: 4px;
}

.comp_paginate_child {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: #12a9cc;
}

.error_message {
  color: red;
}

.custom_marker_container {
  text-align: center;
  width: 60px;
}
.custom_marker {
  top: 3px;
  width: 26px;
  height: 25px;
  position: absolute;
  border-radius: 19.5619px;
  margin-inline-start: 7px;
}

.marker_tab {
  width: 40px;
  height: 40px;
}

.custom_icon_label {
  width: 60px;
  position: relative;
  word-wrap: break-word;
  font-weight: bolder;
  color: red;
  /* width: 100%; */
}
.permission_box_title {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: #3b3b3b;
}

.permission_card {
  border-radius: 10px;
  padding-block: 8px;
  border-color: #12a9cc;
  transition: all 0.2s ease 0s;
  height: 60px;
}

.permission_card:hover {
  border-color: #fff;
  background-color: #12a9cc;
  color: #fff;
  transition: all 0.2s ease 0s;
  height: 60px;
}

.permission_card .icon {
  fill: #12a9cc;
  stroke: #12a9cc;
}

.permission_card:hover .icon {
  fill: #fff;
  stroke: #fff;
}

.permission_card .remove_icon2 {
  color: transparent;
  display: none;
}

.permission_card:hover .remove_icon2 {
  color: #fff;
  cursor: pointer;
  display: initial;
}

.permission_card .title {
  display: inline;
}
.permission_card:hover .title {
  display: none;
}

.modal_close {
  position: absolute;
  top: 0px;
  color: #12a9cc;
  font-size: 20px;
  border-radius: 10%;
  border: 1px solid #12a9cc;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.react-tel-input .country-list .country {
  text-align: center !important;
}

.no-btn-style{
  border: none;
  outline: none;
  background-color: transparent;
}
