.chat_list_card {
  background: none;
  border: none;
  height: 100%;
}

.img_chat_header {
  width: 54.98px;
  height: 54.98px;
  border-radius: 100%;
}
.img_div_chat_header {
  width: fit-content;
  height: fit-content;
  background: #c4c4c4;
  box-sizing: border-box;
  border-radius: 100%;
}

.chat_header_Name {
  font-size: 15px;
  color: #0d1c2e;
}
.nav-link_chat {
  padding: none;
  color: #707c97;
  font-size: 18px;
  border: none;
}

.page_title {
  color: #0d1c2e;
  font-size: 36px;
}

[class*="PrivateTabIndicator"] {
  background: #12a9cc !important;

  border-radius: 3px !important;
  width: 3px !important;
  height: 40px !important;
  left: 0px !important;
}
.PrivateTabIndicator-colorSecondary-947,
.PrivateTabIndicator-colorSecondary-5,
.PrivateTabIndicator-colorSecondary-6 {
  background: #12a9cc !important;

  border-radius: 3px !important;
  width: 3px !important;
  height: 40px !important;
  left: 0px !important;
}
.MuiTab-wrapper {
  justify-content: unset !important;
  flex-direction: unset !important;
}

.MuiBox-root-8 {
  padding: unset !important;
}

.ticket_card {
  width: 100%;
  min-height: 170px;
  height: 200px;
  border: none;
  border-radius: 6px;
  overflow-y: scroll;
}
.ticket_card::-webkit-scrollbar {
  width: 0px !important;
}
.card_img_chat {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}
.chat_title {
  font-size: 18px;
}

.chat_title:hover {
  color: #12a9cc;
}
.chat_desc {
  font-size: 16px;
}
.date_color_chat {
  font-size: 14px;
}

.chat_card_text {
  font-size: 13px;
  text-transform: uppercase;
}

.circleChat {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: #ff3366;
  box-shadow: 4px 4px 15px rgba(198, 27, 74, 0.05),
    2px 2px 10px rgba(198, 27, 74, 0.1), 1px 1px 50px rgba(198, 27, 74, 0.15);
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: lowercase;
  color: #ffffff;
  justify-content: center;
}

.file_btn {
  background: rgba(42, 139, 242, 0.25);
  border-radius: 12.5px;
  border: none;
  width: 88.75px;
  height: 35px;
}
.file_text {
  font-size: 10px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #2a8bf2;
  display: inline-block;
}

.img_btn {
  width: 68.75px;
  height: 35px;
  border-radius: 12.5px;
  border: none;
}
.image_text {
  font-size: 8.75px;
  letter-spacing: -0.01em;
  display: inline-block;
}

.card_box {
  background-color: white;
  border: none;
  width: 100%;
}

.chat_file_btn {
  width: 60px;
  height: 60px;
}

.img_post {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border-color: transparent;
  filter: drop-shadow(4px 4px 15px rgba(154, 81, 48, 0.05)),
    drop-shadow(2px 2px 10px rgba(154, 81, 48, 0.1)),
    drop-shadow(1px 1px 50px rgba(154, 81, 48, 0.15));
}
.post_name {
  font-size: 18px;
}

.nickname_post {
  font-size: 16px;
  text-transform: lowercase;
  color: #2a8bf2;
}

.chat_header_row {
  background: #fafbff;
  border-radius: 10px;
}
.chat_body_card {
  height: 640px;
  overflow: auto;
  border: none;
  display: flex;
  flex-direction: column-reverse;
}

.hor_line {
  background: rgba(112, 124, 151, 0.15);
  border-radius: 2px;
}

.inline_margin_chat {
  margin-inline: 40px;
}
.message_box {
  width: 100%;
  resize: none;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #707c9780;
}

.message_box:focus {
  border: none;
  box-shadow: none;
}

.card_client_message {
  width: fit-content;
  background: linear-gradient(92.68deg, #12a9cc 0%, #00a0e9 100%);
  box-shadow: 10px 10px 25px rgba(42, 139, 242, 0.1),
    15px 15px 35px rgba(42, 139, 242, 0.05),
    10px 10px 50px rgba(42, 139, 242, 0.1);
  border-radius: 0px 10px 10px 10px;
  border: none;
  margin-inline-start: 15px;
  min-height: 50px;
  justify-content: center;
}
.client_message_box_card {
  font-size: 14px;
  color: #ffffff;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.client_img {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #dadada;
  box-shadow: 0px 10px 55px rgba(0, 0, 0, 0.05),
    0px 4px 10px rgba(0, 0, 0, 0.25), 0px 10px 25px rgba(0, 0, 0, 0.05);
  border-radius: 100%;
}
.date_chat {
  font-size: 14px;
  color: rgba(112, 124, 151, 0.7);
}

.card_my_message {
  width: fit-content;
  background: #ffffff;
  border: 1px solid rgba(112, 124, 151, 0.25);
  box-sizing: border-box;
  /* Shadow My Messages */
  box-shadow: 10px 10px 25px rgba(112, 124, 151, 0.05),
    15px 15px 35px rgba(112, 124, 151, 0.05);
  margin-inline-start: 10px;
  border-radius: 0px 10px 10px 10px;
  min-height: 50px;
  justify-content: center;
  /* height: fit-content; */
}
.my_message_box_card {
  font-size: 10px;
  color: #707c97;
}

.fit_content {
  width: fit-content;
}

.chat_search {
  background: #ffffff;
  border-radius: 66px;
  filter: drop-shadow(-5px -5px 10px #fafbff), drop-shadow(5px 5px 10px #a6abbd);
  box-shadow: 5px 5px 10px 0px #a6abbd;
  display: flex;
  align-items: center;
  width: inherit;
  height: 50px;
  width: 100%;
}

.chat_search_input {
  font-size: 18px;
  color: #707c97;
  max-height: 70px;
}

.chat_search_img {
  width: 24px;
  height: 24px;
  margin-inline-start: 20px;
}

.chat_search_dropdown {
  font-size: 18px;
}

.chat_search_input {
  border: none;
  background-color: transparent;
}

.chat_search_input:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.tab1_div {
  /* max-width: 600px; */
  height: 100%;
  width: 600px;
}

/* .MuiTabs-vertical {
  min-width: 17% !important;
} */
.makeStyles-root-3 {
  height: 100% !important;
}

.MuiBadge-colorSecondary {
  background: #00c305 !important;
  border: 1.5px solid #ffffff !important;
  box-sizing: border-box !important;
  width: 9px !important;
  height: 9px !important;
  border-radius: 100% !important;
  margin-inline-start: 4px !important;
}

.chat_box_horizental_line {
  transform: matrix(1, 0, 0, -1, 0, 0);
  background: #707c971a;
  width: 100%;
  border-color: rgba(112, 124, 151, 0.1);
  margin: 0px;
}

.ticket_tab_option {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.68deg, #12a9cc 0%, #00a0e9 100%);
  margin-block: 10px;
}

.ticket_input_form {
  width: 100% !important;
}

.edit_ticket_message_container .modal-content {
  width: fit-content;
}

.form_chat_card {
  background-color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 10%;
  margin-inline: 2px;
  margin-bottom: 10px;
}

.form_chat_card_audio {
  width: 200px;
  height: 200px;
}

.chat_box_file {
  margin-inline: 2px;
}
/* 
.css-1i5alow-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  border-radius: 100% !important;
  width: 40px !important;
  height: 40px !important;
  background-color: #12a9cc !important;
} */
.uiButtonBase-root,
.MuiFab-root,
.MuiFab-circular,
.MuiFab-sizeLarge,
.MuiFab-primary,
.MuiSpeedDial-fab,
.css-md8nuk {
  border-radius: 100% !important;
  width: 40px !important;
  height: 40px !important;
  background-color: #12a9cc !important;
  margin-block: 3px !important;
}
/* 
.jss6 {
  background-color: #12a9cc !important;
} */

.ticket_btn_send {
  background-color: #00a0e9;
  border: none;
  border-radius: 100%;
  width: 37px;
  height: 37px;
  margin-inline: 10px;
}
