.search_container {
    background: #ffffff;
    border: 1.4564px solid #89d1e1;
    /* box-sizing: border-box; */
    /* box-shadow: 8.73837px 8.73837px 15.5349px rgba(163, 177, 198, 0.6),
      -8.73837px -8.73837px 15.5349px rgba(255, 255, 255, 0.6); */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26.7006px;
    width: 100%;

  }

  .search_container .search_icon {
    margin-inline-start: 25px;
  }
  