    .FormInput-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 3px;
    }
    .FormInput-label {
        color: #ffffff;
        text-align: right;
        margin: 8px 0px;
    }
    .FormInput-label-gray {
        color: var(--main-txt);
    }
    .FormInput-data {
        text-align: right;
        width: 100%;
        min-height: 1.5rem;
        height: fit-content;
        background-color: #ffffff;
        color: var(--main-txt);
        padding: 5px;
        border-radius: 6px;
        font-weight: bold;
        border-width: 0px;
        border-width: 1px;
        border-color: inherit;
    }
    .FormInput-data:focus {
        outline: none;
        box-shadow: 0px 0px 2px 1px;
    }
    .error-message {
        color: red;
    }
    .container-form-input {
        text-align: start;
    }